import * as React from "react";
import ManualLayout from "../../components/pages/manual/ManualLayout";
import ManualBody from "../../components/pages/manual/ManualBody";
import Seo from "../../components/seo";

const Learn = () => {
  return (<>
    <ManualLayout selected='learn'>
      <ManualBody
        title='学习资源'
      />
    </ManualLayout>
  </>)
}

export default Learn;

export const Head = () => <Seo />
